import React from "react";
import { notification } from "antd";
import * as Sentry from "@sentry/browser";
import {
  callOptimizer,
  callPlayersForSlate,
  getSlates,
  callJobResults,
} from "./server-interaction/server";
import App from "./App";
import "./App.less";

const IS_DEV = !process.env.NODE_ENV || process.env.NODE_ENV === "development";

if (!IS_DEV) {
  Sentry.init({
    dsn: "https://5c9c0a712c4e4fe18133346858a3ff34@o397075.ingest.sentry.io/5251248",
  });
} else {
  console.log("In local development env.");
}

// Wraps rest of app. Central point for data and settings (if/when persisted)
class AppContainer extends React.Component {
  state = {
    locked: [],
    // TODO - allow to be on per slate baseis
    banned: JSON.parse(localStorage.getItem("state") || "{}").banned || [],
    maxLineups: 6,
    customWeights: undefined,
    useCustomModel: true,
    customExposures:
      JSON.parse(localStorage.getItem("state") || "{}").customExposures || {},
    slates: undefined,
    salaryMin: 48500,
  };

  updateForModelFilters = async (modelFilters) => {
    this.setState({ loading: true });
    const res = await callPlayersForSlate({
      slate_id: this.state.slate_id,
      ...modelFilters,
    });
    this.setState({ players: res.players, loading: false });
  };

  updateForSlate(slate_id) {
    // At this point, do not carry over settings.
    this.setState({ loading: true });
    const slates = getSlates();

    const players = callPlayersForSlate({
      slate_id,
    });
    const optimizedLineups = callOptimizer({
      slate_id,
      // this will cause carry-over behavior unless reset happens
      rosters: this.state.maxLineups,
    });

    Promise.all([players, optimizedLineups, slates])
      .then(([resP, resL, resSlates]) => {
        this.setState({
          slate_id,
          slates: resSlates,
          players: resP.players,
          salaryMax: resP.salary_max_rules,
          optimizedLineups: resL,
          loading: false,
        });
      })
      .catch((e) => {
        // TODO - capture Sentry exception.
        notification.open({
          message: "An unexpected error occurred.",
          description: "Could not fetch player projections.",
        });
        this.setState({
          slate_id,
          players: [],
          optimizedLineups: {
            number_of_lineups: 0,
            rosters: [],
          },
          loading: false,
        });
      });
  }

  setUseCustomModel = (useCustomModel) => {
    this.setState({
      useCustomModel,
    });
  };

  randomId = () => {
    // Math.random should be unique because of its seeding algorithm.
    // Convert it to base 36 (numbers + letters), and grab the first 9 characters
    // after the decimal.
    return Math.random().toString(36).substr(2, 9);
  };

  async generateLineups() {
    localStorage.setItem("state", JSON.stringify(this.state));
    console.log("Saved to localStorage");

    const request_id = this.randomId();
    this.setState({ loading: true, request_id });
    const player_projections = {};
    if (this.state.customWeights && this.state.useCustomModel) {
      this.state.players.forEach((p) => {
        player_projections[p.name] =
          p.custom_projected !== undefined ? p.custom_projected : p.projected;
      });
    }

    // always overrides model
    if (this.state.customProjections) {
      Object.keys(this.state.customProjections).forEach((k) => {
        player_projections[k] = this.state.customProjections[k];
      });
    }

    let res;
    try {
      res = await callOptimizer({
        request_id,
        slate_id: this.state.slate_id,
        locked: this.state.locked,
        banned: this.state.banned,
        rosters: this.state.maxLineups,
        player_projections,
        max_player_exposure: this.state.maxPlayerExposure,
        random_factor: this.state.randomFactor,
        salary_min: this.state.salaryMin,
        salary_max: this.state.salaryMax,
        custom_exposures: Object.values(this.state.customExposures),
      });
    } catch (e) {
      notification.open({
        message: "An unexpected error occurred.",
        description: "Could not generate optimized lineups.",
      });
      this.setState({
        players: [],
        optimizedLineups: {
          number_of_lineups: 0,
          rosters: [],
        },
        loading: false,
      });
      return;
    }

    if (res.job_computing) {
      notification.open({
        message: "Build started",
        description:
          "Now generating lineups. You should see lineups generate and a pop-up when your build is complete.",
      });
      const interval = setInterval(async () => {
        try {
          const res = await callJobResults({
            request_id,
          });
          if (res.complete) {
            clearInterval(interval);
            if (this.state.buildLoading) {
              notification.open({
                message: "Build complete!",
                // TODO - better description of updates
                description: "Your lineups are below",
              });
            }
          }
          this.setState({
            optimizedLineups: res,
            loading: false,
            buildLoading: !res.complete,
          });
        } catch (e) {
          clearInterval(interval);
          notification.open({
            message: "Build failed",
            // TODO - better description of updates
            description:
              "Could not create lineups. Please head to the FGB Slack for help.",
          });
        }
      }, 1000);
    } else {
      this.setState((prevState) => {
        return {
          optimizedLineups: res,
          loading: false,
        };
      });
    }
  }

  addPlayerToLocked(player) {
    this.setState((prevState) => {
      if (prevState.banned.includes(player.solver_id)) {
        this.removePlayerFromBanned(player);
      }
      return {
        locked: [...prevState.locked, player.solver_id],
      };
    });
  }

  addPlayerToBanned(player) {
    this.setState((prevState) => {
      if (prevState.locked.includes(player.solver_id)) {
        this.removePlayerFromLocked(player);
      }
      return {
        banned: [...prevState.banned, player.solver_id],
      };
    });
  }

  setAllPlayersToBanned = () => {
    this.setState((prevState) => {
      return {
        banned: prevState.players.map((p) => p.solver_id),
        locked: [],
      };
    });
  };

  resetBanned = () => {
    this.setState({
      banned: [],
    });
  };

  setMaxLineups = (maxLineups) => {
    this.setState((prevState) => {
      return {
        maxLineups,
      };
    });
  };

  setMaxPlayerExposure = (maxPlayerExposure) => {
    this.setState((prevState) => {
      return {
        maxPlayerExposure,
      };
    });
  };

  setRandomFactor = (randomFactor) => {
    this.setState((prevState) => {
      return {
        randomFactor,
      };
    });
  };

  setSalaryMin = (salaryMin) => {
    this.setState({
      salaryMin,
    });
  };

  setSalaryMax = (salaryMax) => {
    this.setState({
      salaryMax,
    });
  };

  setCustomWeights(customWeights) {
    this.setState((prevState) => {
      return {
        customWeights,
      };
    });

    // Set projections on client
    this.setState((prevState) => {
      // Get total in case no 100
      const totalWeights = customWeights.reduce(
        (accum, curr) => accum + curr.weight,
        0
      );
      const players = prevState.players.map((p) => {
        let projected = 0;

        // Weigh each of the weights
        customWeights.forEach((w) => {
          // TODO - determine how to treat null data cases
          projected +=
            ((w.weight || 0) / totalWeights) * (p[`${w.key}_percentile`] || 0);
        });
        return {
          ...p,
          custom_projected: projected.toFixed(2),
        };
      });

      return {
        players,
      };
    });

    notification.open({
      message: "Projections updated",
      // TODO - better description of updates
      description: "Updated projections for new model",
    });
  }

  setCustomExposure = (exposure) => {
    this.setState((prevState) => {
      const { customExposures } = prevState;
      const newExposures = {
        ...customExposures,
        [exposure.name]: exposure,
      };
      return {
        customExposures: newExposures,
      };
    });
  };

  setCustomProjections = (customProjections) => {
    this.setState({
      customProjections,
    });
  };

  removePlayerFromLocked(player) {
    const current = this.state.locked;
    const index = this.state.locked.indexOf(player.solver_id);
    if (index > -1) {
      current.splice(index, 1);
      this.setState({ locked: current });
    }
  }

  removePlayerFromBanned(player) {
    const current = this.state.banned;
    const index = this.state.banned.indexOf(player.solver_id);
    if (index > -1) {
      current.splice(index, 1);
      this.setState({ banned: current });
    }
  }

  componentDidMount() {
    this.updateForSlate(this.state.slate_id);
  }

  render() {
    return (
      <>
        <App
          locked={this.state.locked}
          banned={this.state.banned}
          loading={this.state.loading}
          buildLoading={this.state.buildLoading}
          maxLineups={this.state.maxLineups}
          setMaxLineups={(maxLineups) => this.setMaxLineups(maxLineups)}
          players={this.state.players}
          optimizedLineups={this.state.optimizedLineups}
          generateLineups={() => this.generateLineups()}
          setContainerState={(slate_id) => this.updateForSlate(slate_id)}
          addPlayerToLocked={(player) => this.addPlayerToLocked(player)}
          removedPlayerFromLocked={(player) =>
            this.removePlayerFromLocked(player)
          }
          addPlayerToBanned={(player) => this.addPlayerToBanned(player)}
          removedPlayerFromBanned={(player) =>
            this.removePlayerFromBanned(player)
          }
          customWeights={this.state.customWeights}
          setCustomWeights={(cs) => this.setCustomWeights(cs)}
          updateForModelFilters={this.updateForModelFilters}
          randomFactor={this.state.randomFactor}
          setRandomFactor={this.setRandomFactor}
          maxPlayerExposure={this.state.maxPlayerExposure}
          setMaxPlayerExposure={this.setMaxPlayerExposure}
          useCustomModel={this.state.useCustomModel}
          setUseCustomModel={this.setUseCustomModel}
          setCustomProjections={this.setCustomProjections}
          salaryMin={this.state.salaryMin}
          salaryMax={this.state.salaryMax}
          setSalaryMin={this.setSalaryMin}
          setSalaryMax={this.setSalaryMax}
          resetBanned={this.resetBanned}
          setAllPlayersToBanned={this.setAllPlayersToBanned}
          customExposures={this.state.customExposures}
          setCustomExposure={this.setCustomExposure}
          slates={this.state.slates}
        />
      </>
    );
  }
}

export default AppContainer;

import React from "react";
import { HotTable } from "@handsontable/react";

import "handsontable/dist/handsontable.full.css";

const COLUMNS = ["Player Name", "Proj."];

const transformToObj = (arr) =>
  arr.reduce((accum, [playername, points]) => {
    const numPoints = Number(points) || 0;
    if (!playername || !numPoints) {
      return accum;
    }

    return {
      ...accum,
      [playername]: numPoints,
    };
  }, {});

export class CustomProjections extends React.Component {
  state = {
    value: 1,
  };

  constructor(props) {
    super(props);
    this.data = Array(200)
      .fill()
      .map(() => ["", ""]);
  }

  onSettingsChange = (e) => {
    this.setState({
      value: e.target.value,
    });
  };

  render() {
    return (
      <div>
        <div>
          Add your own custom projections here. Player names must match the
          names in the "Players" tab.
        </div>
        {/*<RadioGroup onChange={this.onSettingsChange} value={this.state.value}>*/}
        {/*<Radio value={1}>Only Projection Source</Radio>*/}
        {/*<Radio value={2}>Average In</Radio>*/}
        {/*</RadioGroup>*/}
        <div className="CustomProj">
          <HotTable
            settings={{
              data: this.data,
              licenseKey: "non-commercial-and-evaluation",
              colHeaders: COLUMNS,
              rowHeaders: true,
              height: window.innerHeight,
              stretchH: "all",
              // handleChange: this.handleChange,
              observeChanges: true,
              afterChangesObserved: (...args) => {
                const projs = transformToObj(this.data);
                this.props.setCustomProjections(projs, this.state.value);
              },
            }}
          />
        </div>
      </div>
    );
  }
}

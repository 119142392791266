import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import AppContainer from "./AppContainer";
import * as serviceWorker from "./serviceWorker";
import { Menu } from "antd";
import { CheckoutFormContainer } from "./payments/Checkout";
import { Layout } from "antd";
import { withCookies } from "react-cookie";
import Signup from "./user/Signup";
import Login from "./user/Login";
import Account from "./user/Account";
import { useNavigate } from "react-router-dom";
import { UserContext } from "./UserContext";

const { Header, Content } = Layout;

const RawUserWrapper = ({ children, allCookies }) => {
  const [user, setUser] = useState({});

  useEffect(() => {
    const getUser = async () => {
      const userResult = await fetch("/rest-auth/user");
      const user = await userResult.json();
      setUser(user);
    };

    getUser();
  }, []);

  const logout = async () => {
    // On posts, need to pass auth header
    await fetch("/rest-auth/logout/", {
      method: "POST",
      headers: {
        "X-CSRFToken": `${allCookies.csrftoken}`,
      },
    });
    window.location.reload();
  };

  return (
    <UserContext.Provider
      value={{ user, logout, csrfToken: allCookies.csrfToken }}
    >
      {children}
    </UserContext.Provider>
  );
};

const UserWrapper = withCookies(RawUserWrapper);

const Nav = ({ user, logout }) => {
  const hasUser = user && user.email;
  const navigate = useNavigate();
  const premiumFlag = false; // guarded

  const items = [
    {
      label: "Optimizer",
      key: "optimizer",
      onClick: () => {
        navigate("/");
      },
    },
  ];

  if (hasUser) {
    items.push({
      label: `Account (${user.email})`,
      key: "account",
      onClick: () => {
        navigate("/account");
      },
    });
    // items.push({ label: user.email });
    items.push({ label: "Logout", onClick: logout });
    if (premiumFlag) {
      items.push({
        label: "Go Premium",
        onClick: () => {
          navigate("/go-premium");
        },
      });
    }
  } else {
    items.push({
      label: "Log In",
      key: "log-in",
      onClick: () => {
        navigate("/log-in");
      },
    });
    items.push({
      label: "Sign Up",
      key: "sign-up",
      onClick: () => {
        navigate("/sign-up");
      },
    });
  }

  return (
    <Layout>
      <Header>
        <Menu
          items={items}
          mode="horizontal"
          theme={"dark"}
          defaultOpenKeys={["optimizer", "account", "log-in", "sign-up"]}
          onClick={(...args) => {
            console.log(args);
          }}
        />
      </Header>
      <Content>
        <Outlet />
      </Content>
    </Layout>
  );
};

const WrappedNav = () => {
  return (
    <UserContext.Consumer>
      {({ user, logout }) => {
        return <Nav user={user} logout={logout} />;
      }}
    </UserContext.Consumer>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <UserWrapper>
      <UserContext.Consumer>
        {(userContext) => {
          const hasUser = userContext.user.email;

          return (
            <BrowserRouter>
              <Routes>
                <Route path="/*" element={<WrappedNav />}>
                  {!hasUser && <Route path="sign-up" element={<Signup />} />}
                  {!hasUser && <Route path="log-in" element={<Login />} />}
                  {hasUser && (
                    <Route path="log-in" element={<AppContainer />} />
                  )}
                  <Route
                    path="go-premium"
                    element={<CheckoutFormContainer />}
                  />
                  <Route
                    path="account"
                    element={<Account email={userContext.user.email} />}
                  />
                  <Route path="*" element={<AppContainer />} />
                </Route>
              </Routes>
            </BrowserRouter>
          );
        }}
      </UserContext.Consumer>
    </UserWrapper>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import React from "react";
import PropTypes from "prop-types";
import { Tag } from "antd";

const Aggregate = ({
  hasCustomWeightsApplied,
  locked = [],
  banned = [],
  rosters,
  medianScore,
  maxScore,
  minScore,
  randomFactor,
  maxPlayerExposure,
  salaryMin,
  salaryMax,
  positionDistro,
  teamDistro,
  similarityScore,
}) => (
  <div style={{ marginBottom: 10 }}>
    <div className="AggregateContainer">
      {hasCustomWeightsApplied && <Tag color="blue">Custom Model</Tag>}
      {Boolean(randomFactor) && (
        <Tag color="blue">Randomized {randomFactor.toFixed(0)}%</Tag>
      )}
      {Boolean(maxPlayerExposure) && (
        <Tag color="blue">Max Player Exposure {maxPlayerExposure}</Tag>
      )}
      {Boolean(salaryMin) && (
        <Tag color="blue">Combined Salary Min {salaryMin}</Tag>
      )}
      {Boolean(salaryMax) && (
        <Tag color="blue">Combined Salary Max {salaryMax}</Tag>
      )}
      {Boolean(similarityScore) && (
        <Tag color="blue">Lineup Similarity Score {similarityScore}</Tag>
      )}
      {Boolean(locked.length) && <Tag color="blue">{locked.length} Locked</Tag>}
      {Boolean(banned.length) && <Tag color="blue">{banned.length} Banned</Tag>}
    </div>
    <div className="AggregateContainer">
      <Tag color="blue">{`${rosters} Lineups`}</Tag>
      <Tag color="blue">{`Median ${medianScore}`}</Tag>
      <Tag color="blue">{`Max ${maxScore}`}</Tag>
      <Tag color="blue">{`Min ${minScore}`}</Tag>
    </div>
    {/*<code>*/}
    {/*  {*/}
    {/*    positionDistro.map(p => <div>{p}</div>)*/}
    {/*  }*/}
    {/*  {*/}
    {/*    teamDistro.map(p => <div>{p}</div>)*/}
    {/*  }*/}
    {/*</code>*/}
  </div>
);

Aggregate.propTypes = {
  rosters: PropTypes.number.isRequired,
  medianScore: PropTypes.number.isRequired,
  maxScore: PropTypes.number.isRequired,
  minScore: PropTypes.number.isRequired,
};

export default Aggregate;

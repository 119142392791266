import { Col, Row, Select, Checkbox } from "antd";
import React, { useState } from "react";
import { UserContext } from "./UserContext";
import { Alert, Typography } from "antd";

const Option = Select.Option;

export const Slates = ({ slates, setContainerState }) => {
  const [sportFilter, setSportFilter] = useState([]);

  return (
    <UserContext.Consumer>
      {(userContext) => {
        const hasUser = userContext.user.email;

        let usableSlates = slates;
        if (slates && !hasUser) {
          usableSlates = {
            ...slates,
            slates: [slates.slates[0]],
          };
        }

        return (
          <div className="League">
            {!hasUser && (
              <Row style={{ marginBottom: 16, marginTop: 16 }}>
                <Alert
                  message="Create a DraftFast account to see all slates and more optimization options"
                  type="info"
                  showIcon
                />
              </Row>
            )}
            {slates && (
              <Row>
                <Col span={16} style={{ marginBottom: 16, marginTop: 16 }}>
                  <Typography.Text type="secondary" style={{ marginRight: 16 }}>
                    Slates
                  </Typography.Text>
                  {Object.entries(slates.sports).map(([sport, count]) => {
                    return (
                      <Checkbox
                        key={sport}
                        disabled={!hasUser}
                        onClick={(e) => {
                          const checked = e.target.checked;
                          if (checked) {
                            setSportFilter([...sportFilter, sport]);
                          } else {
                            setSportFilter(
                              sportFilter.filter((s) => s !== sport)
                            );
                          }
                        }}
                      >
                        {sport} ({count})
                      </Checkbox>
                    );
                  })}
                </Col>
                <Col span={8} style={{ marginBottom: 16, marginTop: 16 }}>
                  <Select
                    defaultValue={slates.slates[0].name}
                    style={{ width: 250 }}
                    onChange={(slate_id) => {
                      setContainerState(slate_id);
                    }}
                  >
                    {usableSlates.slates
                      .filter((s) => {
                        if (!sportFilter.length) {
                          return true;
                        }

                        return sportFilter.includes(s.sport);
                      })
                      .map((s) => {
                        return (
                          <Option key={s.slate_id} value={s.slate_id}>
                            {s.name}
                          </Option>
                        );
                      })}
                  </Select>
                </Col>
              </Row>
            )}
          </div>
        );
      }}
    </UserContext.Consumer>
  );
};

import React from "react";
import { Button, Form, Input } from "antd";

const invokeLogin = async (creds) => {
  const res = await fetch(`/rest-auth/login/`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(creds),
  });
  if (res.status !== 200) {
    return res.json();
  }

  return "okay";
};

const Login = () => {
  const onFinish = (values) => {
    invokeLogin(values).then((res) => {
      if (res === "okay") {
        if (window.heap) {
          window.heap.identify();
          window.heap.addUserProperties({
            email: values.username,
          });
        }
        setTimeout(() => {
          window.location.replace("https://www.dailyfantasyheat.com/");
        }, 3000);
      } else {
        alert(`Error logging in: ${JSON.stringify(res)}`);
      }
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Form
      style={{ margin: "auto", marginTop: 35, width: 375 }}
      name="basic"
      labelCol={{
        span: 8,
      }}
      wrapperCol={{
        span: 16,
      }}
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <Form.Item
        label="Username"
        name="username"
        rules={[
          {
            required: true,
            message: "Please enter your username",
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Password"
        name="password"
        rules={[
          {
            required: true,
            message: "Please enter your password!",
          },
        ]}
      >
        <Input.Password />
      </Form.Item>

      <Form.Item
        wrapperCol={{
          offset: 8,
          span: 16,
        }}
      >
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default Login;

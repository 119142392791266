import React, { useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Spin, List, Row, Col, Typography } from "antd";
import { Elements } from "@stripe/react-stripe-js";

const stripePromise = loadStripe(
  "pk_test_51KFkM8KdxLpvTJtv435sNUzUvsUap3epGPrcOy5FtiYZvtkv9rCBlZBNwjWEy0cyOrZYqFEtqliIpsIaZbfJuEet00lGB2k6tm"
);
// Render an Elements provider at the root of your React app so that it is available everywhere you need it.
// https://stripe.com/docs/payments/quickstart
// https://stripe.com/docs/billing/subscriptions/build-subscription?ui=elements

export const CheckoutFormContainer = (props) => {
  console.log(props);
  const options = {
    // passing the client secret obtained from the server
    clientSecret:
      "pi_3KG58zKdxLpvTJtv1H5wmflh_secret_QI6ADWir1lYkI1URufv3b9D70",
  };

  return (
    <Elements stripe={stripePromise} options={options}>
      <CheckoutForm />
    </Elements>
  );
};

const CheckoutForm = () => {
  const [ready, setReady] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = useState(null);

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const { error } = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: "https://dailyfantasyheat.com/",
      },
    });

    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      setErrorMessage(error.message);
    } else {
      console.log("it worked!");
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  const options = {
    business: {
      name: "DraftFast LLC",
    },
  };

  const data = [
    {
      title: "✅ CSV download access",
      description: "Download lineups to DraftKings instantly",
    },
    {
      title: "✅ 20+ lineup single runs",
      description: "Compete in 150 lineup contests",
    },
    {
      title: "✅ Full slate access",
      description: "Compete in all Showdown and other available slates",
    },
  ];

  return (
    <div style={{ margin: 32, height: 400 }}>
      <Row>
        <Col span={6}>
          <List
            header={
              <Typography.Title level={3}>$20 Premium Tier</Typography.Title>
            }
            itemLayout="horizontal"
            dataSource={data}
            renderItem={(item) => (
              <List.Item>
                <List.Item.Meta
                  title={item.title}
                  description={item.description}
                />
              </List.Item>
            )}
          />
        </Col>
        <Col span={16}>
          <div style={{ marginLeft: 16, marginTop: 16 }}>
            <form onSubmit={handleSubmit}>
              <PaymentElement
                options={options}
                onReady={() => setReady(true)}
              />
              {!ready && <Spin />}
              {ready && (
                <button style={{ marginTop: 8 }} disabled={!stripe}>
                  Submit
                </button>
              )}
              {/* Show error message to your customers */}
              {errorMessage && <div>{errorMessage}</div>}
            </form>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default CheckoutFormContainer;

import React from "react";
import { Button, notification } from "antd";
import { CSVLink } from "react-csv";
import { DownloadOutlined } from "@ant-design/icons";

const SHOWDOWN_HEADER = ["CPT", "UTIL", "UTIL", "UTIL", "UTIL", "UTIL"];
const NFL_SHOWDOWN_HEADER = ["CPT", "FLEX", "FLEX", "FLEX", "FLEX", "FLEX"];
const NFL_CLASSIC_HEADER = [
  "QB",
  "RB",
  "RB",
  "WR",
  "WR",
  "WR",
  "TE",
  "FLEX",
  "DST",
];
const CPT_SHOWDOWN_HEADER_PGA = ["CPT", "G", "G", "G", "G", "G"];
const SHOWDOWN_HEADER_PGA = ["G", "G", "G", "G", "G", "G"];
const CLASSIC_HEADERS_NASCAR = ["D", "D", "D", "D", "D", "D"];
const MLB_CLASSIC_HEADERS = [
  "P",
  "P",
  "C",
  "1B",
  "2B",
  "3B",
  "SS",
  "OF",
  "OF",
  "OF",
];

const buildCSVData = (rosters, ruleSetInfo) => {
  let headers = SHOWDOWN_HEADER;
  if (ruleSetInfo.sport === "NFL_SHOWDOWN") {
    headers = NFL_SHOWDOWN_HEADER;
  }
  if (ruleSetInfo.sport === "NFL") {
    headers = NFL_CLASSIC_HEADER;
  }
  if (ruleSetInfo.sport === "PGA_CAPTAIN") {
    headers = CPT_SHOWDOWN_HEADER_PGA;
  }
  if (ruleSetInfo.sport === "PGA") {
    headers = SHOWDOWN_HEADER_PGA;
  }
  if (ruleSetInfo.sport === "NASCAR") {
    headers = CLASSIC_HEADERS_NASCAR;
  }
  if (ruleSetInfo.sport === "MLB") {
    headers = MLB_CLASSIC_HEADERS;
  }

  return [headers, ...rosters.map((r) => r.players.map((p) => p.key))];
};

export const OptimizerCSV = ({ rosters, ruleSetInfo }) => {
  const csv = buildCSVData(rosters, ruleSetInfo);
  return (
    <CSVLink style={{ marginLeft: "4px" }} data={csv} filename="draftfast.csv">
      <Button
        type="primary"
        icon={<DownloadOutlined />}
        loading={!csv}
        onClick={() => {
          if (window.heap) {
            window.heap.track("CSV Download");
          }
          notification.open({
            message: `${rosters.length} Lineups Downloaded`,
            description: (
              <p>
                Lineups downloaded as CSV and available for upload{" "}
                <a
                  href="https://www.draftkings.com/lineup/upload"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  here
                </a>
              </p>
            ),
          });
        }}
      >
        Download
      </Button>
    </CSVLink>
  );
};

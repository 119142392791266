import React, { useState } from "react";
import { Spin, Tabs, Button, Row, Col } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { LineupTable } from "./LineupCard";
import { PlayersTable } from "./players-table/Players";
import { Slates } from "./Slates";
import "./App.less";
import Settings from "./settings/Settings";
import Aggregate from "./Aggregate";
import { OptimizerCSV } from "./OptimizerCSV";
import { CustomProjections } from "./CustomProjections";

const { TabPane } = Tabs;

function App({
  loading,
  buildLoading,
  players,
  locked,
  banned,
  optimizedLineups,
  setContainerState,
  addPlayerToLocked,
  removedPlayerFromLocked,
  addPlayerToBanned,
  removedPlayerFromBanned,
  generateLineups,
  setMaxLineups,
  maxLineups,
  customWeights,
  setMaxPlayerExposure,
  maxPlayerExposure,
  randomFactor,
  setRandomFactor,
  setCustomProjections,
  salaryMin,
  salaryMax,
  setSalaryMin,
  setSalaryMax,
  resetBanned,
  setAllPlayersToBanned,
  customExposures,
  setCustomExposure,
  slates,
}) {
  const [asPercentiles] = useState(false);
  return (
    <div className="App">
      <header className="App-header">
        <Row>
          <Col span={32} className="MainOptimizerControls">
            <Slates slates={slates} setContainerState={setContainerState} />
          </Col>
        </Row>
        <Tabs type="card" defaultActiveKey="1">
          <TabPane tab="Players" key="1">
            <PlayersTable
              loading={loading}
              asPercentiles={asPercentiles}
              addPlayerToLocked={addPlayerToLocked}
              removePlayerFromLocked={removedPlayerFromLocked}
              addPlayerToBanned={addPlayerToBanned}
              removePlayerFromBanned={removedPlayerFromBanned}
              locked={locked}
              banned={banned}
              dataSource={players}
              resetBanned={resetBanned}
              setAllPlayersToBanned={setAllPlayersToBanned}
              customExposures={customExposures}
              setCustomExposure={setCustomExposure}
            />
          </TabPane>
          <TabPane tab="Optimizer" key="2">
            <div className="ButtonControls">
              <Button
                className="ControlButton"
                onClick={() => {
                  generateLineups();
                  if (window.heap) {
                    window.heap.track("Lineup Generation", {
                      locked: JSON.stringify(locked),
                      banned: JSON.stringify(banned),
                      customWeights: JSON.stringify(customWeights),
                    });
                  }
                }}
                type="primary"
                loading={loading || buildLoading}
                icon={<PlusOutlined />}
              >
                Generate
              </Button>
              <Settings
                locked={locked}
                banned={banned}
                maxLineups={maxLineups}
                setMaxLineups={setMaxLineups}
                setMaxPlayerExposure={setMaxPlayerExposure}
                maxPlayerExposure={maxPlayerExposure}
                randomFactor={randomFactor}
                setRandomFactor={setRandomFactor}
                salaryMin={salaryMin}
                salaryMax={salaryMax}
                setSalaryMin={setSalaryMin}
                setSalaryMax={setSalaryMax}
              />
              {optimizedLineups && optimizedLineups.rosters && (
                <OptimizerCSV
                  rosters={optimizedLineups.rosters}
                  ruleSetInfo={optimizedLineups.ruleset_info}
                />
              )}
            </div>
            <div className="TableResults">
              {optimizedLineups && optimizedLineups.number_of_rosters === 0 && (
                <div>
                  <p>No solution found.</p>
                </div>
              )}
              {optimizedLineups && optimizedLineups.number_of_rosters > 0 ? (
                <div>
                  <Aggregate
                    maxPlayerExposure={optimizedLineups.max_player_exposure}
                    randomFactor={optimizedLineups.random_factor}
                    locked={optimizedLineups.constraint_args.locked}
                    banned={optimizedLineups.constraint_args.banned}
                    hasCustomWeightsApplied={
                      optimizedLineups.has_custom_projections
                    }
                    medianScore={optimizedLineups.median}
                    maxScore={optimizedLineups.max}
                    minScore={optimizedLineups.min}
                    rosters={optimizedLineups.number_of_rosters}
                    salaryMin={optimizedLineups.salary_min}
                    salaryMax={optimizedLineups.salary_max}
                    positionDistro={optimizedLineups.by_pos}
                    teamDistro={optimizedLineups.by_team}
                    similarityScore={optimizedLineups.similarity_score}
                  />
                  <LineupTable
                    exposures={optimizedLineups.exposures}
                    dataSource={optimizedLineups.rosters}
                  />
                </div>
              ) : !optimizedLineups ||
                optimizedLineups.number_of_rosters === undefined ? (
                <Spin />
              ) : (
                <div />
              )}
            </div>
          </TabPane>
          <TabPane tab="Custom Proj." key="3">
            <CustomProjections setCustomProjections={setCustomProjections} />
          </TabPane>
        </Tabs>
      </header>
    </div>
  );
}

export default App;

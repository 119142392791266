import { Table, Tag, Row, Col } from "antd";
import { LockFilled } from "@ant-design/icons";
import React from "react";

const columns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Team",
    dataIndex: "team",
    key: "team",
  },
  {
    title: "Proj.",
    dataIndex: "projected",
    key: "projected",
  },
  {
    title: "Sal.",
    dataIndex: "salary",
    key: "salary",
    render: (text) => {
      const t = (text / 1000).toFixed(1);
      return `${t}K`;
    },
  },
  {
    title: "Pos",
    dataIndex: "position",
    key: "position",
  },
  {
    title: "Lock?",
    dataIndex: "locked",
    key: "locked",
    width: 25,
    render: (a, player) => {
      if (player.locked) {
        return (
          <LockFilled
            style={{
              paddingLeft: "10%",
            }}
          />
        );
      }
    },
  },
];

const LineupFeedback = ({ idx, projected, spent }) => {
  return (
    <div className="LineupFeedback">
      <Tag color="green">{`Lineup ${idx}`}</Tag>
      <Tag color="green">{`Projected ${projected}`}</Tag>
      <Tag color="green">{`Spent ${spent}`}</Tag>
    </div>
  );
};

const gather = (arr, chunk = 3) => {
  let i;
  let j;
  const hold = [];
  for (i = 0, j = arr.length; i < j; i += chunk) {
    hold.push(arr.slice(i, i + chunk));
  }

  return hold;
};

const exposureColumns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    ellipsis: true,
    width: 100,
    // TODO - custom render to include position here
    // render: (n) => {
    //   const parts = n.split(" ");
    //   const totalParts = parts.length;
    //   return `${parts[totalParts - 3]} ${parts[totalParts - 2]}`;
    // },
  },
  {
    title: "R",
    dataIndex: "requested",
    key: "requested",
    width: 75,
    render: (dct) => {
      if (dct && Object.keys(dct).length) {
        const str = `${dct.min} - ${dct.max}`;
        if (dct.success) {
          return <div style={{ color: "green" }}>{str}</div>;
        }
        return <div style={{ color: "red" }}>{str}</div>;
      }

      return "";
    },
  },
  {
    title: "#",
    dataIndex: "lineups",
    key: "lineups",
    width: 50,
  },
  {
    title: "%",
    dataIndex: "pct",
    key: "pct",
    width: 50,
    render: (t) => t.toFixed(0),
  },
];

export const LineupTable = ({ dataSource, exposures }) => {
  const chunked = gather(dataSource);
  return (
    <div style={{ marginBottom: 30 }}>
      <Row align={"stretch"}>
        <Col span={5}>
          <div>
            <div className="LineupFeedback">
              <Tag color={"green"}>Exposure</Tag>
            </div>
            <Table
              dataSource={exposures}
              columns={exposureColumns}
              pagination={false}
              size={"small"}
            />
          </div>
        </Col>
        <Col span={18}>
          {chunked.map((chunk, i) => (
            <Row key={i}>
              {chunk.map((line, r) => {
                const num = r + 1 + i * chunked[0].length;
                return (
                  <Col span={8}>
                    <div style={{ marginBottom: 20, marginRight: 4 }} key={num}>
                      <LineupFeedback
                        idx={num}
                        projected={line.projected}
                        spent={line.cost}
                      />
                      <Table
                        key={line.id}
                        dataSource={line.players}
                        columns={columns}
                        pagination={false}
                        size={"small"}
                      />
                    </div>
                  </Col>
                );
              })}
            </Row>
          ))}
        </Col>
      </Row>
    </div>
  );
};

import { Table, Checkbox, Input, Space, Button, InputNumber } from "antd";
import React from "react";
import { SearchOutlined } from "@ant-design/icons";

const DEFAULT_WIDTH = {
  minWidth: 7,
  width: 7,
};

const generateColumns = (
  getColumnSearchProps,
  locked,
  banned,
  addPlayerToLocked,
  removePlayerFromLocked,
  addPlayerToBanned,
  removePlayerFromBanned,
  asPercentiles,
  resetBanned,
  setAllPlayersToBanned,
  players,
  customExposures,
  setCustomExposure
) => {
  const realPositions = new Array(
    ...new Set((players || []).map((p) => p.sport_position))
  );

  const setMaxExposure = (player) => (max_exposure) => {
    let hit = customExposures[player.name];
    if (hit) {
      hit.max_exposure = max_exposure;
      setCustomExposure(hit);
    } else {
      setCustomExposure({
        name: player.solver_id,
        max_exposure,
        min_exposure: 0,
      });
    }
  };

  const setMinExposure = (player) => (min_exposure) => {
    let hit = customExposures[player.solver_id];
    if (hit) {
      hit.min_exposure = min_exposure;
      setCustomExposure(hit);
    } else {
      setCustomExposure({
        name: player.solver_id,
        min_exposure,
        max_exposure: 100,
      });
    }
  };

  return [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 5,
      minWidth: 5,
      fixed: "left",
      ...getColumnSearchProps("name"),
    },
    {
      title: "S. Pos",
      dataIndex: "position",
      key: "position",
      width: 2,
      minWidth: 2,
      filters: [
        { text: "CPT", value: "CPT" },
        { text: "FLEX", value: "FLEX" },
      ],
      onFilter: (value, record) => record.position === value,
      fixed: "left",
    },
    {
      title: "Pos",
      dataIndex: "sport_position",
      key: "sport_position",
      width: 2,
      minWidth: 2,
      filters: realPositions.map((p) => {
        return {
          text: p,
          value: p,
        };
      }),
      onFilter: (value, record) => record.sport_position === value,
      fixed: "left",
    },
    {
      title: "Team",
      dataIndex: "team",
      key: "team",
      width: 2,
      minWidth: 2,
      sorter: (a, b) => {
        if (a.team > b.team) {
          return 1;
        }

        if (a.team === b.team) {
          return 0;
        }

        return -1;
      },
      onFilter: (value, record) => record.team === value,
      fixed: "left",
    },
    // {
    //   title: "CPos",
    //   dataIndex: "classic_position",
    //   key: "classic_position",
    //   width: 2,
    //   minWidth: 2,
    //   filters: [
    //     // TODO - should not be NFL specific
    //     // and should come from server
    //     { text: "QB", value: "QB" },
    //     { text: "WR", value: "WR" },
    //     { text: "RB", value: "RB" },
    //     { text: "TE", value: "TE" },
    //     { text: "K", value: "K" },
    //     { text: "DEF", value: "DEF" },
    //   ],
    //   onFilter: (value, record) => record.classic_position === value,
    //   fixed: "left",
    // },
    {
      title: "Salary",
      dataIndex: "salary",
      key: "salary",
      fixed: "left",
      sorter: (a, b) => a.salary - b.salary,
      render: (text) => {
        const t = (text / 1000).toFixed(1);
        return `${t}K`;
      },
      width: 2,
      minWidth: 2,
    },
    {
      title: "Min Exp.",
      dataIndex: "min_exposure",
      key: "min_exposure",
      width: 2,
      minWidth: 2,
      render: (a, player) => {
        const fn = setMinExposure(player);
        return (
          <InputNumber
            min={0}
            max={100}
            style={{ width: 75 }}
            value={(customExposures[player.solver_id] || {}).min_exposure || 0}
            onChange={fn}
          />
        );
      },
    },
    {
      title: "Max Exp.",
      dataIndex: "max_exposure",
      key: "max_exposure",
      width: 2,
      minWidth: 2,
      render: (a, player) => {
        const fn = setMaxExposure(player);
        return (
          <InputNumber
            min={0}
            max={100}
            style={{ width: 75 }}
            value={
              (customExposures[player.solver_id] || {}).max_exposure || 100
            }
            onChange={fn}
          />
        );
      },
    },
    {
      ...DEFAULT_WIDTH,
      title: () => {
        return (
          <div>
            Ban
            <Checkbox
              style={{ marginLeft: 4 }}
              checked={
                (players || []).length &&
                (banned || []).length >= (players || []).length
              }
              onChange={(e) => {
                if (e.target.checked) {
                  setAllPlayersToBanned();
                } else {
                  resetBanned();
                }
              }}
            />
          </div>
        );
      },
      filters: [
        { text: "Banned", value: "banned" },
        { text: "Available", value: "available" },
      ],
      onFilter: (value, record) => {
        if (value === "banned") {
          return banned.includes(record.solver_id);
        }
        return !banned.includes(record.solver_id);
      },
      dataIndex: "ban",
      key: "ban",
      render: (a, player) => {
        return (
          <Checkbox
            checked={banned.includes(player.solver_id)}
            onChange={(e) => {
              if (e.target.checked) {
                addPlayerToBanned(player);
              } else {
                removePlayerFromBanned(player);
              }
            }}
          />
        );
      },
    },
    {
      ...DEFAULT_WIDTH,
      title: "Lock?",
      dataIndex: "locked",
      key: "locked",
      render: (a, player) => {
        return (
          <Checkbox
            checked={locked.includes(player.solver_id)}
            onChange={(e) => {
              if (e.target.checked) {
                addPlayerToLocked(player);
              } else {
                removePlayerFromLocked(player);
              }
            }}
          />
        );
      },
      filters: [
        { text: "Locked", value: "locked" },
        { text: "Unlocked", value: "Unlocked" },
      ],
      onFilter: (value, record) => {
        if (value === "locked") {
          return locked.includes(record.solver_id);
        }
        return !locked.includes(record.solver_id);
      },
    },
    {
      title: "DF Proj.",
      dataIndex: "projected",
      key: "projected",
      ...DEFAULT_WIDTH,
      sorter: (a, b) => a.projected - b.projected,
    },
  ];
};

export class PlayersTable extends React.Component {
  state = {
    searchText: "",
    searchedColumn: "",
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => text,
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  render() {
    const {
      dataSource,
      addPlayerToLocked,
      removePlayerFromLocked,
      addPlayerToBanned,
      removePlayerFromBanned,
      locked,
      banned,
      loading,
      resetBanned,
      setAllPlayersToBanned,
      customExposures,
      setCustomExposure,
    } = this.props;
    return (
      <div>
        <Table
          rowClassName="TableRow"
          dataSource={dataSource}
          columns={generateColumns(
            (dIdx) => this.getColumnSearchProps(dIdx),
            locked,
            banned,
            addPlayerToLocked,
            removePlayerFromLocked,
            addPlayerToBanned,
            removePlayerFromBanned,
            this.props.asPercentiles,
            resetBanned,
            setAllPlayersToBanned,
            dataSource,
            customExposures,
            setCustomExposure
          )}
          scroll={{ x: 2000, y: window.innerHeight * 0.85 }}
          pagination={{
            pageSize: 100,
          }}
          loading={loading}
        />
      </div>
    );
  }
}

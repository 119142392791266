import React from "react";
import { Tag } from "antd";

export default (props) => (
  <div>
    <div>
      {props.locked.length > 0 && (
        <div className="FilterText">
          <p className={"Locked"}>Locked Players</p>
          {props.locked.map((p) => (
            <Tag key={p} color={"green"}>
              {p}
            </Tag>
          ))}
        </div>
      )}
      {props.locked.length === 0 && (
        <div className="FilterText">
          <p className={"Locked"}>Locked Players</p>
          <p>
            You haven't locked any players; to lock players, click the Players
            tab and check a box or boxes.
          </p>
        </div>
      )}
    </div>
    <div>
      {props.banned.length > 0 && (
        <div className="FilterText">
          <p className={"Locked"}>Excluded Players</p>
          {props.banned.map((p) => (
            <Tag key={p} color={"orange"}>
              {p}
            </Tag>
          ))}
        </div>
      )}
      {props.banned.length === 0 && (
        <div className="FilterText">
          <p className={"Locked"}>Excluded Players</p>
          <p>
            You haven't excluded any players; to exclude players, click the
            Players tab and check a box or boxes.
          </p>
        </div>
      )}
    </div>
  </div>
);

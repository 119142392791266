import React, { Component } from "react";
import { Button, Row } from "antd";
import Modal from "react-modal";
import { SettingOutlined } from "@ant-design/icons";
import LockBan from "./LockBan";
import MiniControl from "./MiniControl";

Modal.setAppElement("#root");
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    minWidth: "50%",
    height: "90%",
  },
};

class Settings extends Component {
  state = {
    modalIsOpen: false,
    reset: null,
  };

  // Can use hooks
  closeModal = () => {
    this.setState({ modalIsOpen: false });
  };

  render() {
    const { modalIsOpen } = this.state;
    const {
      maxLineups,
      setMaxLineups,
      maxPlayerExposure,
      setMaxPlayerExposure,
      randomFactor,
      setRandomFactor,
      salaryMin,
      setSalaryMin,
      salaryMax,
      setSalaryMax,
    } = this.props;

    if (!modalIsOpen) {
      return (
        <Button
          onClick={() => this.setState({ modalIsOpen: true })}
          type="primary"
          loading={false}
          icon={<SettingOutlined />}
        >
          {this.props.numFilters
            ? `Settings (${this.props.numFilters})`
            : "Settings"}
        </Button>
      );
    }

    return (
      <div className="SettingsContainer">
        <Modal
          isOpen={this.state.modalIsOpen}
          // onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closeModal}
          style={customStyles}
          contentLabel="Settings"
        >
          <h2>Optimizer Settings</h2>
          <h4 style={{ color: "green" }}>Lineup Exposure</h4>
          <Row>
            <MiniControl
              defaultValue={maxLineups || 3}
              value={maxLineups || 3}
              inputType="number"
              title="Max Lineups"
              description="The maximum number of lineups to generate"
              onChange={(...args) => setMaxLineups(...args)}
              min={1}
              max={20}
            />
          </Row>
          <Row>
            <MiniControl
              defaultValue={maxPlayerExposure || 0}
              value={maxPlayerExposure || 0}
              inputType="number"
              title="Max Player Exposure"
              description="The maximum number of lineups one player can be in. Overrides locking."
              onChange={(...args) => setMaxPlayerExposure(...args)}
              min={0}
              max={150}
            />
          </Row>
          <Row>
            <MiniControl
              defaultValue={randomFactor || 0}
              value={randomFactor || 0}
              inputType="number"
              title="Randomize"
              description="Randomize projections by the selected percentage (direction is random)."
              onChange={(...args) => setRandomFactor(...args)}
              min={0}
              max={150}
            />
          </Row>
          <h4 style={{ color: "green" }}>Salary Settings</h4>
          <Row>
            <MiniControl
              defaultValue={salaryMax || 0}
              value={salaryMax || 0}
              inputType="number"
              title="Max Total Salary"
              description="Max combined salary of players"
              onChange={(...args) => setSalaryMax(...args)}
              min={0}
              max={60000}
              step={100}
            />
          </Row>
          <Row>
            <MiniControl
              defaultValue={salaryMin || 48500}
              value={salaryMin || 48500}
              inputType="number"
              title="Min. Total Salary"
              description="Min combined salary of players"
              onChange={(...args) => {
                setSalaryMin(...args);
              }}
              min={0}
              max={60000}
              step={100}
            />
          </Row>
          <h4 style={{ color: "green" }}>Locks and Bans</h4>
          <LockBan {...this.props} />
        </Modal>
      </div>
    );
  }
}

// Settings.propTypes = {
//   passUp: PropTypes.func.isRequired,
//   lp: PropTypes.number.isRequired,
//   avg: PropTypes.number.isRequired,
//   ms: PropTypes.number.isRequired,
//   sp: PropTypes.number.isRequired,
//   lockedPlayers: PropTypes.array.isRequired,
//   bannedPlayers: PropTypes.array.isRequired,
//   bannedTeams: PropTypes.array.isRequired,
//   numFilters: PropTypes.number.isRequired,
// };

export default Settings;

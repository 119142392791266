import React from "react";
import { Spin } from "antd";

const Account = ({ email }) => {
  if (!email) {
    return <Spin />;
  }

  return <div> Logged in as {email} </div>;
};

export default Account;

const OPTIMIZE_URL = `/api/golf/optimize_beta/`;
const PLAYERS_URL = `/api/golf/players_beta/`;
const SLATES_URL = `/api/golf/slates_beta`;
const JOB_URL = `/api/golf/jobs/`;

const GET = "GET";
const POST = "POST";
const CONTENT_TYPE = "Content-Type";
const APPLICATION_JSON = "application/json";

export const getSlates = async () => {
  const res = await fetch(SLATES_URL, {
    method: GET,
    headers: {
      [CONTENT_TYPE]: APPLICATION_JSON,
    },
  });

  return res.json();
};

export const callOptimizer = async (postBody) => {
  const res = await fetch(OPTIMIZE_URL, {
    method: POST,
    headers: {
      [CONTENT_TYPE]: APPLICATION_JSON,
    },
    body: JSON.stringify(postBody),
  });

  return res.json();
};

export const callPlayersForSlate = async (postBody) => {
  const res = await fetch(PLAYERS_URL, {
    method: POST,
    headers: {
      [CONTENT_TYPE]: APPLICATION_JSON,
    },
    body: JSON.stringify(postBody),
  });

  return res.json();
};

export const callJobResults = async (postBody) => {
  const res = await fetch(JOB_URL, {
    method: POST,
    headers: {
      [CONTENT_TYPE]: APPLICATION_JSON,
    },
    body: JSON.stringify(postBody),
  });

  return res.json();
};
